import * as React from "react";
import {
  Header,
  Hero,
  Services,
  FeaturedClients,
  TechSpecialties,
  FeaturedProjects,
  OurApproach,
  Footer,
} from "../components";

const IndexPage = () => (
  <main>
    <Header />
    <Hero />
    <Services />
    <FeaturedClients />
    <TechSpecialties />
    <FeaturedProjects />
    <OurApproach />
    <Footer />
  </main>
);

export default IndexPage;
